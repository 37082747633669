<template>
	<div style="position: relative;">
		<template v-for="i in Math.ceil(workers.length / 2, 10)">
			<div v-if="hasElement(2 * (i - 1))" :key="i" class="card-container">
				<div class="w-card flex-full">
					<b-img class="water-mark" :src="require('@/assets/images/logo/logo.png')" alt="logo" />
					<div class="d-flex align-items-center pb-2">
						<b-img :src="require('@/assets/images/logo/logo.png')" alt="logo" width="48" />
						<h4 class="mr-2 mb-0">مشروع الاشغال العامة</h4>
					</div>
					<div class="d-flex justify-content-between text-right info-line pt-2" style="gap: 2rem">
						<div class="flex-fill">
							<div class="pb-1">
								<span>الاسم :</span>
								<h2 class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1)].name }}</h2>
							</div>
							<div class="info-line py-1">
								<span>رقم العامل :</span>
								<p class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1)].id }}</p>
							</div>
						</div>
						<div class="">
							<qrcode-vue :value="`${workers[2 * (i - 1)].id}`" size="100" level="H" />
						</div>
					</div>
					<div class="info-line py-1 text-right mt-1">
						<span> المشروع :</span>
						<p class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1)].project }}</p>
					</div>
				</div>
				<div v-if="hasElement(2 * (i - 1) + 1)" class="w-card flex-full">
					<b-img class="water-mark" :src="require('@/assets/images/logo/logo.png')" alt="logo" />
					<div class="d-flex align-items-center pb-2">
						<b-img :src="require('@/assets/images/logo/logo.png')" alt="logo" width="48" />
						<h4 class="mr-2 mb-0">مشروع الاشغال العامة</h4>
					</div>
					<div class="d-flex justify-content-between text-right info-line pt-2" style="gap: 2rem">
						<div class="flex-fill">
							<div class="pb-1">
								<span>الاسم :</span>
								<h2 class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1) + 1].name }}</h2>
							</div>
							<div class="info-line py-1">
								<span>رقم العامل :</span>
								<p class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1) + 1].id }}</p>
							</div>
						</div>
						<div class="">
							<qrcode-vue :value="`${workers[2 * (i - 1) + 1].id}`" size="100" level="H" />
						</div>
					</div>
					<div class="info-line py-1 text-right mt-1">
						<span> المشروع :</span>
						<p class="d-inline-block mr-1 mb-0">{{ workers[2 * (i - 1) + 1].project }}</p>
					</div>
				</div>
			</div>
			<div v-if="isLastRow(i)" :key="`break-${i}`" class="pagebreak"></div>
		</template>

		<b-button v-b-tooltip.hover.top="`Print`" class="btn-icon rounded-circle new-field-btn" variant="primary" size="lg" @click="print">
			<feather-icon icon="PrinterIcon" size="20" />
		</b-button>
	</div>
</template>

<script>
import { BImg, BButton, VBTooltip } from 'bootstrap-vue';
import QrcodeVue from 'qrcode.vue';
import { getState } from '@/utils/helpers';

export default {
	name: 'Cards',

	components: { BImg, BButton, QrcodeVue },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		project: '',
		workers: [],
	}),

	created() {
		this.project = getState('currentProject')?.name ?? '';
		this.workers = (getState('workers-cards-info') ?? []).map((w) => ({
			id: w.mobile_id ?? w.id,
			name: w.full_name,
			project: this.project,
		}));
	},

	methods: {
		isLastRow(i) {
			return i % 7 === 0;
		},

		hasElement(i) {
			return i < this.workers.length;
		},

		print() {
			window.print();
		},
	},
};
</script>

<style lang="scss">
.w-card {
	position: relative;
	background-color: white;
	border: 1px solid #333;
	padding: 1rem 2rem;
	direction: rtl;
	flex: 1;
	overflow: hidden;

	* {
		z-index: 1;
	}

	.info-line {
		border-top: 1px dashed #333;
	}
}

.water-mark {
	position: absolute;
	inset: 0;
	width: 90%;
	z-index: 0;
	opacity: 0.1;
}

.card-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 4rem;
	margin-bottom: 2rem;
}

.new-field-btn {
	position: fixed;
	bottom: 3%;
	right: 2%;
	z-index: 999;
}

@media print {
	body {
		background-color: white;
		color: #000;
	}

	.pagebreak {
		page-break-before: always;
	}

	.new-field-btn {
		display: none;
	}
}
</style>
